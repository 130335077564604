import "./App.css";
import Content from "./components/content/Content";
import Footer from "./components/footer/Footer";
import Topbar from "./components/topbar/Topbar";

function App() {
  return (
    <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column app-base">
      <Topbar />
      <Content />
      <Footer/>
    </div>
  );
}

export default App;
