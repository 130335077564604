import { SaveOne } from "@icon-park/react";
import { Tab, Tabs } from "react-bootstrap";
import "./style.css";
import Gallery from "./Gallery";
import Fade from "react-reveal/Fade";

const HowTo = () => {
  return (
    <div className="w-75" id="howto">
      <div className="big-space" />
      <div className="big-space" />
      <Fade bottom>
        <div className="glass-card mx-auto">
          <h1 className="text-center">How to stake with Carbonara</h1>
          <p className="text-center">Select a method</p>
          <hr />
          <div className="card-img-icon-center">
            <SaveOne className="mt-4" size={50} />
          </div>
          <Tabs
            defaultActiveKey="wallet"
            id="uncontrolled-tab-example"
            className="mb-3"
            variant="tabs"
          >
            <Tab eventKey="wallet" title="Step by step">
              <ul>
                <li> 1 - Open Lisk Desktop Wallet</li>
                <hr />
                <li> 2 - Access your wallet</li>
                <hr />
                <li> 3 - On the top bar search for <b>carbonara</b></li>
                <hr />
                <li> 4 - Click on "Add to votes" button</li>
                <hr />
                <li> 5 - Input the amount to stake (recommended the whole balance)</li>
                <hr />
                <li> 6 - On the top bar click on "Voting queue" icon and press continue</li>
                <hr />
                <li> 7 - Done</li>
              </ul>
            </Tab>
            <Tab eventKey="how" title="Screenshots guide">
              {/* <Terminal /> */}
              <Gallery />
            </Tab>
          </Tabs>
        </div>
      </Fade>
    </div>
  );
};

export default HowTo;
