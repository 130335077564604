import { Rocket } from "@icon-park/react";
import {  Nav } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./style.css";

const Why = () => {
  return (
    <div className="w-75" id="why">
      <div className="big-space" />
      <div className="big-space" />
      <Fade bottom>
        <div className="data-block glass-card mx-auto">
          <div className="card-img-icon-right">
            <Rocket className="mt-4" size={50} />
          </div>
          <h1>Why choose Carbonara - WeStake:Club ⚡️</h1>
          <hr />
          <p className="justify-text">
            Carbonara is part of a blockchain ecosystem provider named <b>WeStake.Club⚡️</b>. We are a team of dedicated people that love to devolop blockchain
            ecosystems. People already staked on us over 500M+ USD across
            different blockchain projects. Our staking infrastructure is spread in
            datacenters around the world and relies on over 6 dedicates nodes for the Lisk
            ecosystem.<br /><br/>
            <b>WeStake.Club ⚡️</b> is a community around staking projects, become member of our community together with over 700 members on Discord.
            <br/><br/>
            Do you need help to stake or you found any issue? Send a message to
            Carbonara#5880 on Discord, we will be happy to help. Join our<Nav.Link className="a-no-style" style={{display: 'inline'}} href={"https://discord.gg/4GnkRqwsDK"} target="_blank" >Discord server</Nav.Link> too
          </p>
        </div>
      </Fade>
    </div>
  );
};

export default Why;
