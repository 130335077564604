import { Col, Container, Nav, Row } from "react-bootstrap";
import "./style.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <Container>
        <Row className="w-75 mx-auto">
          <Col>
            <p className="footer-logo">We<b>Stake</b>.Club⚡️ <br/> <span className="feat-small">x</span><br/>Lisk</p>
          </Col>
          <Col>
            <h3 className="mt-4 ml-4">What we do</h3>
            <Row>
              <Col>
                <ul>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://lisk.observer"} target="_blank" >Lisk Observer</Nav.Link></li>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://lisk.vote"} target="_blank" >Lisk.vote</Nav.Link></li>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://lisk.com/blog/announcement/introducing-lisk-community-squad"} target="_blank" >Lisk Community Squad & Lisk Mod</Nav.Link></li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://github.com/nerdvibe/lisk-cute-assistant"} target="_blank" >Lisk Cute Assistant</Nav.Link></li>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://github.com/nerdvibe/lisk-offline"} target="_blank" >Lisk Offline Wallet</Nav.Link></li>
                  <li className="mt-2"><Nav.Link className="a-no-style" href={"https://github.com/nerdvibe"} target="_blank" >Github</Nav.Link></li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
