import { Col, Container, Nav, Row } from "react-bootstrap";
import { Copy } from "react-feather";
import Lisk from "./assets/Lisk.png";
import "./style.css";

const Landing = () => {
  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = "carbonara";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return (
    <Container className="landing-container center w-75 " id="home">
      <div className="mx-auto w-100">
        <div className="landing-background"></div>
        <div className="z-10 mobile-padding">
          <Row>
            <Col className="glass-card mx-auto">
              <div className="card-img">
                <img src={Lisk} alt="Lisk logo" />
              </div>
              <h1 className="text-center">
                Carbonara ⚡️ <span className="feat-small">x</span> Lisk
              </h1>
              <hr />
              <div>
                <p>
                  Stake your Lisk tokens with <b>Carbonara</b> and earn block rewards{" "}
                  without worries while supporting the Lisk Ecosystem. WeStake.Club pool is simple and transparent, we share 50% of all of our block rewards and we support the development of the ecosystem. 
                  <br/>
                  <br/>
                  Do you need assistnace with staking? Join our <Nav.Link className="a-no-style discord-link" href="https://discord.com/invite/4GnkRqwsDK">Discord Server</Nav.Link> and we'll help you. Best delegate in
                  town ;)
                </p>
                <Nav.Link href="#howto">
                  <div className="learn-how-button mb-3">Learn how {">>"}</div>
                </Nav.Link>
                <div className="address-block">
                  <code>carbonara</code> <Copy className="pointer" onClick={copyToClipboard}/>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default Landing;
