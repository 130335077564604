import { Planet } from "@icon-park/react";
import { Col, Nav, Row } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import DiscordIcon from './assets/discord.svg';
import TwitterIcon from './assets/twitter.svg';
import GithubIcon from './assets/github.svg';
import "./style.css";

const Social = () => {
  return (
    <div className="w-75" id="social">
      <div className="big-space" />
      <div className="big-space" />
      <Fade bottom>
        <div className="data-block glass-card mx-auto">
          <div className="card-img-icon-right">
            <Planet className="mt-4" size={50} />
          </div>
          <h1>Follow us</h1>
          <hr />
          <Row>
            <Col xs={4}>
              <Nav.Link className="social-icon" target="_blank" href="https://discord.com/invite/4GnkRqwsDK"><img src={DiscordIcon} alt="discord" /></Nav.Link>
            </Col>
            <Col xs={4}>
              <Nav.Link className="social-icon" target="_blank" href="https://twitter.com/CarbonaraCrypto"><img className="twitter" src={TwitterIcon} alt="twitter" /></Nav.Link>
            </Col>
            <Col xs={4}>
              <Nav.Link className="social-icon" target="_blank" href="https://github.com/nerdvibe"><img src={GithubIcon} alt="github" /></Nav.Link>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
};

export default Social;
