import { Container } from "react-bootstrap";
import Advantages from "../advantages/Advantages";
import Disclaimer from "../disclaimer/Disclaimer";
import HowTo from "../how-to/HowTo";
import Landing from "../landing/Landing";
import Social from "../social/Social";
import Why from "../why/Why";
import "./style.css";

const Content = () => {
  return (
    <div>
      <Landing />
      <div className="content-container">
        <Container className="center vertical-flex">
            <Why />
            <Advantages />
            <HowTo />
            <Social />
            <Disclaimer />
        </Container>
      </div>
    </div>
  );
};

export default Content;
