import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import "./style.css";

const Topbar = () => {
  return (
    <header className="mb-auto topbar-container z-10">
      <Navbar variant="dark">
        <Container>
          <div className="mx-auto w-75">
            <Row>
              <Col>
                <Navbar.Brand href="#home">
                  We<b>Stake</b>.Club⚡️
                </Navbar.Brand>
              </Col>
              <Col>
                <Nav className="me-auto justify-content-end">
                  <Nav.Link href="#why">Why</Nav.Link>
                  <Nav.Link href="#advantages">Advantages</Nav.Link>
                  <Nav.Link href="#howto">How to</Nav.Link>
                </Nav>
              </Col>
            </Row>
          </div>
        </Container>
      </Navbar>
    </header>
  );
};

export default Topbar;
