import { Col, Row } from "react-bootstrap";
import { FlashPayment } from "@icon-park/react";
import "./style.css";
import Fade from "react-reveal/Fade";

const Advantages = () => {
  return (
    <div className="w-75" id="advantages">
      <div className="big-space" />
      <div className="big-space" />
      <Fade bottom>
        <div className="data-block glass-card mx-auto">
          <div className="card-img-icon-left">
            <FlashPayment className="mt-4" size={50} />
          </div>
          <h1 style={{ textAlign: "right" }}>Advantages</h1>
          <hr />
          <Row>
            <Col xs={12} lg={4}>
              <ul>
                <li className="big-text">50% Share</li>
                <hr />
                <li className="big-text">Active ecosystem development</li>
                <hr className="show-mobile" />
              </ul>
            </Col>
            <Col xs={12} lg={4}>
              <ul>
                <li className="big-text">Bi-weekly payouts</li>
                <hr />
                <li className="big-text">Support with Staking on Discord</li>
                <hr className="show-mobile" />
              </ul>
            </Col>
            <Col xs={12} lg={4}>
              <ul>
                <li className="big-text">Active member</li>
                <hr />
                <li className="big-text">High uptime & Proven track record</li>
              </ul>
            </Col>
          </Row>
        </div>
      </Fade>
    </div>
  );
};

export default Advantages;
