import { Nav } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import "./style.css";

const Disclaimer = () => {
  return (
    <div className="w-75" id="social">
      <div className="big-space" />
      <div className="big-space" />
      <div className="w-100">
        <Fade bottom>
          <div className="w-fit-content mx-auto">
            <h1 className="fw-100 w-fit-content">
              Delegate now & <br />
              Earn rewards & <br />
              Join us on <Nav.Link className="a-no-style discord-link" href="https://discord.com/invite/4GnkRqwsDK">Discord.</Nav.Link> 
            </h1>
          </div>
        </Fade>
      </div>
      <div className="big-space" />
      <div className="big-space" />
      <div className="big-space" />
    </div>
  );
};

export default Disclaimer;
