import { Carousel } from "react-bootstrap";
import firstStep from './assets/1.png';
import secondStep from './assets/2.png';
import thirdStep from './assets/3.png';
import fourthStep from './assets/4.png';
import fifthStep from './assets/5.png';
import sixthStep from './assets/6.png';
import seventhStep from './assets/7.png';
import "./gallery.css"

const Gallery = () => {
  return (
    <Carousel>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={firstStep}
        alt="Open Lisk Desktop"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">1) Open Lisk Desktop</h3>
        <p className="carousel-text">..and let's proceed to the login</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={secondStep}
        alt="Access your wallet"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">2) Access your wallet</h3>
        <p className="carousel-text">Access your wallet by using your mnemonic seed</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={thirdStep}
        alt="First slide"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">3) Search for Carbonara</h3>
        <p className="carousel-text">On the top bar search for carbonara</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={fourthStep}
        alt="Add to votes"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">4) Add to votes</h3>
        <p className="carousel-text">Click on "Add to votes" button</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={fifthStep}
        alt="Amount to stake"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">5) Amount to stake</h3>
        <p className="carousel-text">Input the amount to stake (recommended the whole balance)</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={sixthStep}
        alt="Vote broadcast"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">6) Vote broadcast</h3>
        <p className="carousel-text">On the top bar click on "Voting queue" icon and press continue</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={seventhStep}
        alt="Done"
      />
      <Carousel.Caption className="hide-mobile">
        <h3 className="carousel-text">Done!</h3>
        <p className="carousel-text">Now you are accumulating rewards.</p>
      </Carousel.Caption>
    </Carousel.Item>
    </Carousel>
  );
};

export default Gallery;
